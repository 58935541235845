/* Can't style the html tag with theme-ui, so put styles in here */

html,body {
  height: 100%;
  background-position: 0px -30vh;
    background-repeat: no-repeat;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 122, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.MuiButton-root,.MuiTab-root,.MuiTypography-button{
  text-transform: none!important;
}

.MuiPopover-root{
  z-index: 1600!important;
}

input{
  font-weight: 600!important;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  color: #9DA0AA!important;
}

input::-moz-placeholder {
  font-size: 12px;
  /* Mozilla Firefox 19+ */
  color: #9DA0AA!important;
}

input:-moz-placeholder {
  font-size: 12px;
  /* Mozilla Firefox 4 to 18 */
  color: #9DA0AA!important;
}

input:-ms-input-placeholder {
  font-size: 12px;
  /* Internet Explorer 10-11 */
  color: #9DA0AA!important;
}